/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    code: "code",
    h2: "h2",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "align-self",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#align-self",
    "aria-label": "align self permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Align Self"), "\n", React.createElement(_components.p, null, "Utilities for controlling how an individual flex or grid item is positioned along its container's cross axis."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, null, "React props"), React.createElement(_components.th, null, "CSS Properties"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, null, React.createElement(_components.code, null, "alignSelf={keyword}")), React.createElement(_components.td, null, React.createElement(_components.code, null, "align-self: {keyword};"))))), "\n", React.createElement(_components.h2, {
    id: "auto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#auto",
    "aria-label": "auto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Auto"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "alignSelf=\"auto\""), " to align an item based on the value of the container's ", React.createElement(_components.code, null, "align-items"), " property:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=emerald"
  }, "<>\n  <template preview>\n    <x.div h={24} display=\"flex\" alignItems=\"stretch\" spaceX={2}>\n      <x.div\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"emerald-400\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        1\n      </x.div>\n      <x.div\n        alignSelf=\"auto\"\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"emerald-600\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        2\n      </x.div>\n      <x.div\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"emerald-400\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        3\n      </x.div>\n    </x.div>\n  </template>\n  <x.div display=\"flex\" alignItems=\"stretch\">\n    <div>1</div>\n    <x.div alignSelf=\"auto\">2</x.div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "start",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#start",
    "aria-label": "start permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Start"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "alignSelf=\"flex-start\""), " to align an item to the start of the container's cross axis, despite the container's ", React.createElement(_components.code, null, "align-items"), " value:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=amber"
  }, "<>\n  <template preview>\n    <x.div h={24} display=\"flex\" alignItems=\"stretch\" spaceX={2}>\n      <x.div\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"amber-400\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        1\n      </x.div>\n      <x.div\n        alignSelf=\"start\"\n        py={3}\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"amber-600\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        2\n      </x.div>\n      <x.div\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"amber-400\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        3\n      </x.div>\n    </x.div>\n  </template>\n  <x.div display=\"flex\" alignItems=\"stretch\">\n    <div>1</div>\n    <x.div alignSelf=\"flex-start\">2</x.div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "center",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#center",
    "aria-label": "center permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Center"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "alignSelf=\"center\""), " to align an item along the center of the container's cross axis, despite the container's ", React.createElement(_components.code, null, "align-items"), " value:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=light-blue"
  }, "<>\n  <template preview>\n    <x.div h={24} display=\"flex\" alignItems=\"stretch\" spaceX={2}>\n      <x.div\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"light-blue-400\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        1\n      </x.div>\n      <x.div\n        alignSelf=\"center\"\n        py={3}\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"light-blue-600\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        2\n      </x.div>\n      <x.div\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"light-blue-400\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        3\n      </x.div>\n    </x.div>\n  </template>\n  <x.div display=\"flex\" alignItems=\"stretch\">\n    <div>1</div>\n    <x.div alignSelf=\"center\">2</x.div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "end",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#end",
    "aria-label": "end permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "End"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "alignSelf=\"flex-end\""), " to align an item to the end of the container's cross axis, despite the container's ", React.createElement(_components.code, null, "align-items"), " value:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=red"
  }, "<>\n  <template preview>\n    <x.div h={24} display=\"flex\" alignItems=\"stretch\" spaceX={2}>\n      <x.div\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"red-400\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        1\n      </x.div>\n      <x.div\n        alignSelf=\"flex-end\"\n        py={3}\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"red-600\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        2\n      </x.div>\n      <x.div\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"red-400\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        3\n      </x.div>\n    </x.div>\n  </template>\n  <x.div display=\"flex\" alignItems=\"stretch\">\n    <div>1</div>\n    <x.div alignSelf=\"flex-end\">2</x.div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "stretch",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#stretch",
    "aria-label": "stretch permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Stretch"), "\n", React.createElement(_components.p, null, "Use ", React.createElement(_components.code, null, "alignSelf=\"stretch\""), " to stretch an item to fill the container's cross axis, despite the container's ", React.createElement(_components.code, null, "align-items"), " value:"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx",
    meta: "preview color=fuchsia"
  }, "<>\n  <template preview>\n    <x.div h={24} display=\"flex\" alignItems=\"flex-start\" spaceX={2}>\n      <x.div\n        py={3}\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"fuchsia-400\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        1\n      </x.div>\n      <x.div\n        alignSelf=\"stretch\"\n        py={3}\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"fuchsia-600\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        2\n      </x.div>\n      <x.div\n        py={3}\n        flex=\"1 1 0\"\n        borderRadius=\"md\"\n        bg=\"fuchsia-400\"\n        color=\"white\"\n        display=\"flex\"\n        alignItems=\"center\"\n        justifyContent=\"center\"\n        fontSize=\"2xl\"\n        fontWeight=\"extrabold\"\n      >\n        3\n      </x.div>\n    </x.div>\n  </template>\n  <x.div display=\"flex\" alignItems=\"flex-start\">\n    <div>1</div>\n    <x.div alignSelf=\"stretch\">2</x.div>\n    <div>3</div>\n  </x.div>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "responsive",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#responsive",
    "aria-label": "responsive permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Responsive"), "\n", React.createElement(_components.p, null, "To control the alignment of flex items at a specific breakpoint, use responsive object notation. For example, adding the property ", React.createElement(_components.code, null, "alignSelf={{ md: \"center\" }}"), " to an element would apply the ", React.createElement(_components.code, null, "alignSelf=\"center"), " utility at medium screen sizes and above."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<x.div display=\"grid\" alignSelf={{ md: 'center' }}>\n  {/* ... */}\n</x.div>\n")), "\n", React.createElement(_components.p, null, "For more information about xstyled's responsive design features, check out ", React.createElement(_components.a, {
    href: "/docs/responsive-design/"
  }, "Responsive Design"), " documentation."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
